@import "src/styles/main";

.remarks-modal{
  border-radius: 0px;
.header-container {
  padding:8px;
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius:0px; 
  background-color: $unilever-white;
}
.modal-heading {
  display:flex;
  flex-direction: row;
  align-items: center;
  gap:5px;
  justify-content: center;
  p {
    font-size: 12px;
    margin-top:9px;
  }
}
.modal-footer {
  border-radius: 0px;
  background-color: $unilever-white;
}
}