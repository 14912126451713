@import 'src/styles/variables';

.validation-period-summary {
  .table-component tbody tr td {
    border-left: none;
    border-right: none;
  }

  .table-component {
    // thead tr:nth-child(1) {

    //   th:nth-child(7),
    //   th:nth-child(8) {
    //     background-color: $table-header-group-data-cell-color;
    //   }
    // }

    thead tr th:nth-child(11) {
      border-left: 1px solid $table-border;
    }

    thead tr th:nth-child(6) {
      border-left: none !important;
    }

    thead tr th:nth-child(7),
    thead tr th:nth-child(8) {
      border-left: 2px solid $table-border;
    }

    thead:nth-child(7) {
      border-left: 2px solid $table-border;
    }

    thead tr:nth-child(2) {

      th:nth-child(7),
      th:nth-child(8) {
        border-left: 2px solid $table-border;
      }

      th:nth-child(9),
      th:nth-child(10),
      th:nth-child(11) {
        border-left: 1px solid $table-border;
      }

      th:nth-child(7),
      th:nth-child(8),
      th:nth-child(9),
      th:nth-child(10),
      th:nth-child(11) {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        opacity: 0.7;
      }
    }

    tbody tr td {
      border-left: none;
      border-right: none;
      cursor: pointer;
    }

    tbody tr td:nth-child(7),
    tbody tr td:nth-child(8),
    tbody tr td:nth-child(9),
    tbody tr td:nth-child(10),
    tbody tr td:nth-child(11) {
      border-left: 1px solid $table-border;
    }

    tbody tr td:nth-child(6) {
      border-left: none !important;
    }
  }
}