@import "src/styles/main";

.modal-container {
  
  .modal-header {
    background-color: $table-header;
    border-bottom:0px;
    padding:30px;
  }
  .modal-body {
    background-color: $table-header;
    padding:0px 54px 0px 54px;
    overflow-y: hidden;
    h2 {
      font-family: 'unilever-shilling';
      font-size: 20px;
      line-height: 37px;
    }
    h1 {
      font-family: 'unilever-shilling-medium';
      font-size:24px;
      line-height: 37px;
    }
  }
}
