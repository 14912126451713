@import "src/styles/variables";

.pagination {

    background: $unilever-white;

    >div {
        padding: 10px;
    }

    p,
    ul {
        margin-bottom: 0px;
    }

    .page-item {
        margin: 0 4px 0 0;

        .page-link {
            border: none;
            background: unset;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 26px;
            color: #09a75e;
            cursor: pointer;
            padding: 0 10px;
        }

        &.active {
            background: #d7f3e6;

            .page-link {
                cursor: auto;
            }
        }

        &.disabled {
            .page-link {
                color: #D9D9D9;

                .left-arrow-icon, 
                .right-arrow-icon{
                    height: 16px;
                }
            }
        }
    }

    .left-arrow-icon {
        margin-right: 10px;
        
    }

    .right-arrow-icon {
        margin-left: 10px;
    }

    .records-label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.7);
    }
}