@import 'src/styles/main';

.page-container {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;

  .page-heading {
    font-family: 'unilever-shilling';
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.2px;
    color: #000000;
    margin-bottom: 10px;
  }

  .top-row {
    padding: 0px;
    justify-content: space-between;

    .breadcrumb-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .filters-container {
      display: flex;

      flex-direction: row;
      align-items: center;
      margin: 0px;
      gap: 12px;

      .filter-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;

        p {
          font-family: 'unilever-shilling-medium';
        }
      }

      .filters-container-text {
        font-size: 12px;
      }
    }

    .dropdown-volatility {
      padding: '12px 23px 12px 12px';
      width: 188px;
    }

    .tab-container {
      padding: 0px;
      margin: 0px;
    }
  }
}
