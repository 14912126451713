@import 'src/styles/main';
$myButtonColor: #09a75e;

.volatility-mapping {
  margin: 0px;
  padding: 0px;
  .top-row {
    margin-top: 0px;
    padding: 0px;
    .button-primary {
      border-radius: 0px;
      background-color: $myButtonColor;
      border: 1px solid $myButtonColor;
      color: white;
    }

    .button-outline {
      border-radius: 0px;
      background-color: transparent;
      border: 1px solid $myButtonColor;
      color: $myButtonColor;
    }

    .button-outline:hover {
      color: white;
      background-color: $myButtonColor;
    }
    .button-outline:active {
      background-color: $myButtonColor;
    }
    .individual-file-info {
      background-color: white;
      width: 100%;
      padding: 0.6rem 1rem 0 1rem;
      margin: auto;
      p {
        font-size: 12px;
      }
      .status {
        display: flex;
        align-items: center;
      }

      .status > p {
        font-size: 12px;
      }
      .success-tag {
        padding: 0px 8px 2px 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: rgb(246, 127, 127);
        color: black;
        border-radius: 4px;
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
      }
      .failed-tag {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 20px;
        padding: 0px 8px 2px 8px;
        background-color: rgb(169, 243, 169);
        color: black;
        border-radius: 4px;
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
      }
    }
    .breadcrumb-container {
      .page-header {
        width: 100%;
      }
    }
    .remarks-modal {
      border-radius: 0px;
    }
    .file-name-row {
      display: flex;
      flex-direction: row;
      margin: 5px 0px 40px 0px;
      .file-change-btn {
        cursor: disabled;
      }
      p {
        margin: 0px 0px 0px 8px;
        color: $grey-shade-1;
        font-size: 16px;
        line-height: 16px;
        margin-top: 5px;
        font-family: 'unilever-shilling';
      }
      a {
        margin: 0px 0px 0px 3px;
        font-size: 14px;
        line-height: 14px;
        color: $primary-color;
      }
    }

    .menu-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: -10px;
      .menu-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
      .left-container {
        max-height: 240px;
        .item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          color: $grey-shade-1;
          padding: 0px;
          gap: 7px;
          padding-block: 0px;
          width: 100%;

          .success-tag {
            padding: 0px 8px 2px 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $success-tag;
            color: $unilever-white;
            border-radius: 4px;
            font-weight: 400;
            font-size: 10px;
            line-height: 18px;
          }
          .failed-tag {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 20px;
            padding: 0px 8px 2px 8px;
            background-color: $failed-tag;
            color: $unilever-white;
            border-radius: 4px;
            font-weight: 400;
            font-size: 10px;
            line-height: 18px;
          }
          h2 {
            font-family: 'unilever-shilling-medium';
            font-size: 12px;
            margin: 0px;
          }
          p {
            font-weight: 200;
            margin: 0px;
            font-size: 12px;
            line-height: 24px;
          }
          .small-text {
            font-size: 10px;
          }
        }
      }
    }
    .right-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 21px;
      .excel-button {
        background-color: $unilever-white;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;
        padding: 2px 22px 2px 22px;
        p {
          margin-top: 12px;
          margin-right: 8px;
        }
        .arrow-button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 30px;
          margin-left: 2px;
          background-color: $error-bg;
        }
      }
      .remarks-button {
        background-color: $unilever-white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 0px 16px 0px 16px;
        color: $unilever-blue;
        font-family: 'unilever-shilling-medium';
        p {
          margin-top: 13px;
        }

        .remarks-text {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .middle-container {
      margin: 12px 0px 52px 0px;
      .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 0px;
        margin-top: 2px;
        width: 100%;
        h2 {
          font-weight: bold;
          font-size: 14px;
          font-size: bold;
        }
        p {
          margin-top: 10px;
          font-weight: 200;
          font-size: 14px;
        }
      }
    }

    .table-container {
      margin-top: 30px;
      margin-inline: 0px;
      padding: 0px;
      position: relative;
      .last-row-btns {
        display: flex;
        top: 300px;
        flex-direction: row;
        justify-content: flex-end;
        background-color: $footer-bg;
        padding: 12px 22px 12px 22px;
        gap: 30px;
        .right-btns {
          display: inline-flex;
          gap: 12px;

          .btn-container {
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
      background-color: transparent;
      .left-table-container {
        margin: 0;
        padding: 0;
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 3px 0px 0px 3px;
          font-family: 'unilever-shilling-medium';
        }
        .header {
          background-color: $table-empty-cell-color;
          display: flex;
          height: 100%;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin: 0;
          padding: 16px;

          .second {
            display: flex;
            flex-direction: row;
            gap: 8px;
            .col-number {
              border-right: 1px solid $grey-shade-1;
              padding: 4px 6px 6px 6px;
              font-family: 'unilever-shilling';
              color: $grey-shade-1;
              font-size: 14px;
            }
            .error {
              color: #ff9998;
              display: flex;
              justify-content: center;
              background-color: $error-bg;
              padding: 4px 6px 6px 6px;
              font-weight: 600;
              align-items: center;
              font-size: 14px;
              img {
                margin-top: 4px;
                margin-right: 5px;
              }
            }
          }
        }
      }
      .right-table-container {
        padding: 16px 0px 16px 16px;
        border-left: 1px solid $table-border;
        background-color: $table-empty-cell-color;
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;
          p {
            font-family: 'unilever-shilling-medium';
          }
        }
        .update-button {
          background-color: $error-bg;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 4px;
          padding: 8px 8px 10px 8px;
          color: $main-text-black;
        }
        .critical {
          display: flex;
          align-items: last baseline;
          .mark-as-critical {
            font-size: 2rem;
            display: block;
            opacity: 70%;
            color: $border-color-grey;
            height: fit-content;
            &-title {
              font-size: 0.875rem;
              margin: 0;
              align-self: center;
              margin-top: 0.25rem;
            }
            img {
              align-self: center;
            }
          }
        }
      }

      .end-table-container {
        padding: 0px;
      }
    }
    .table-content {
      padding: 0px;
      margin: 0px;
      .left-col {
        background-color: $unilever-white;
        color: $grey-shade-1;
        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 37px;
          padding-right: 57px;
          padding-block: 2px;
        }
        .row-item {
          border-bottom: 1px solid $unilever-disabled-grey;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
      .right-col {
        border-left: 1px solid $unilever-disabled-grey;
        padding: 0px;
        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-block-start: 0px;
          padding-block-end: 0px;
          padding-inline-start: 27px;
          padding-right: 1rem;

          .target-item {
            font-size: 14px;
          }
          .critical-icon {
            border: none;
            background: none;
            padding: 0 1rem;
          }
        }
        .toggle-switch {
          padding-top: 0.5rem;
          padding-bottom: 0.75rem;
        }
        p {
          padding: 0px;
          margin-top: 8px;
        }
      }
    }

    .dropdown-container a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #73706e;
    }

    .source-header,
    .target-header {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.26px;
      opacity: 0.7;
      color: #000000;
      margin: 0;
    }

    .update-action {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.2px;
      color: #000000;
    }
  }
}
