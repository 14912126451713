@import 'src/styles/main';
$myButtonColor: rgb(0, 31, 130);

.validation-dashboard {
  box-sizing: border-box;
  padding: 0px;
  margin-inline: 0px;

  .container-div {
    width: 50%;
    background-color: red;
  }

  .button-primary {
    background-color: $myButtonColor;
    border: 1px solid $myButtonColor;
    color: white;
  }

  .button-outline {
    background-color: transparent;
    border: 1px solid $myButtonColor;
    color: $myButtonColor;
  }

  .button-outline:active {
    background-color: $myButtonColor;
  }

  .button-outline:hover {
    color: white;
    background-color: $myButtonColor;
  }

  .rowDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    padding: 8px;
    margin: 0px;
  }

  .dropdown-container {
    width: 40%;
    background-color: transparent;
    border: 0px;
    display: flex;
    justify-content: space-between;

    .dropdown-volatility {
      padding: '12px 23px 12px 12px';
    }
  }

  .page-heading {
    font-family: 'unilever-shilling';
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 10px;
    line-height: 30px;
  }

  .dashboard-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
    padding: 0px 12px 0px 15px;
  }

  .top-row {
    width: 100%;
    padding: 0px;
    margin: 0px;
    justify-content: flex-stsart;

    .breadcrumb-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .fields-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 105px;
    padding: 8px;
    padding: 6.5px 0px 11px 0px;
    align-items: center;
    justify-content: space-between;
  }

  .right-end-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
  }

  .filters-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0px;

    .filter-text {
      margin-top: 8px;
      font-size: 12px;
      padding-right: 3px;
    }

    .filter-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      justify-content: space-around;

      p {
        font-family: 'unilever-shilling-medium';
      }

      &:last-child {
        padding-left: 10px;
      }
    }
  }

  .filters-container-second {
    display: inline-flex;
    width: 100%;
    gap: 12px !important;
    margin-bottom: 36px;

    .filter-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      p {
        font-family: 'unilever-shilling-medium';
      }
    }
    .filter-container-same-line {
      flex: 1;
    }
  }

  .search-container {
    display: inline-flex;
    justify-content: flex-end;
    margin-block-end: 33px;

    .search-inner {
      width: 65%;
    }
  }

  .table-component {
    // thead tr:nth-child(1) {
    //   th:nth-child(7),
    //   th:nth-child(8) {
    //     background-color: $table-header-group-data-cell-color;
    //   }
    // }

    thead tr th:nth-child(11) {
      border-left: 1px solid $table-border;
    }

    thead tr th:nth-child(6) {
      border-left: none !important;
    }

    thead tr th:nth-child(7),
    thead tr th:nth-child(8) {
      border-left: 2px solid $table-border;
    }

    thead:nth-child(7) {
      border-left: 2px solid $table-border;
    }

    thead tr:nth-child(2) {
      th:nth-child(7),
      th:nth-child(8) {
        border-left: 2px solid $table-border;
      }

      th:nth-child(9),
      th:nth-child(10),
      th:nth-child(11),
      th:nth-child(12),
      th:nth-child(13),
      th:nth-child(14) {
        border-left: 1px solid $table-border;
      }

      th:nth-child(7),
      th:nth-child(8),
      th:nth-child(9),
      th:nth-child(10),
      th:nth-child(11),
      th:nth-child(12),
      th:nth-child(13),
      th:nth-child(14) {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        opacity: 0.7;
      }
    }

    tbody tr td {
      border-left: none;
      border-right: none;
      cursor: pointer;
    }

    tbody tr td:nth-child(7),
    tbody tr td:nth-child(8),
    tbody tr td:nth-child(9),
    tbody tr td:nth-child(10),
    tbody tr td:nth-child(11),
    tbody tr td:nth-child(12),
    tbody tr td:nth-child(13),
    tbody tr td:nth-child(14) {
      border-left: 1px solid $table-border;
    }

    tbody tr td:nth-child(6) {
      border-left: none !important;
    }
  }

  .dropdown-container .dropdown-styling:nth-child(2) {
    margin-left: 20px;
  }

  .cell-hyperlink {
    color: $link-blue;
    text-decoration: underline;
    cursor: pointer;
  }

  .table-container {
    overflow-x: scroll;
  }

  .tab-container {
    border-bottom: 1px solid $unilever-disabled-grey;

    button {
      border-radius: 0px;
      border: 1px solid $primary-color;
      color: $primary-color;
      background: transparent;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;

      &:hover {
        color: white;
        background-color: $primary-color;
      }

      &.active {
        color: white;
        background-color: $primary-color;
      }
    }
  }
}
