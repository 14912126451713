@import "src/styles/main";

.breadcrumb-component {
    
    .card-template-header-left-icon {
        display: flex;
        align-items: center;
        margin-top: 6px;
    }

    .breadcrumb-component-item {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        padding-left: 0px;
        display: flex;
        font-family: 'unilever-shilling';
        margin-left: 6px;

        a {
            color: $grey-shade-1;
            text-decoration: none;
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
        }

        &.active {
            color: $unilever-dark-blue;
            padding-left: 0px;
            font-size: 18px;
            line-height: 30px;
            font-family: 'unilever-shilling-medium';
            color: $primary-color;
        }

        &:before {
            padding-right: 6px;
        }
    }

}