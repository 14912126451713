@import "src/styles/main";

.sidebar-container {
    height: 100%;
    position: fixed;
    width:203px;
    // padding: 0px 4px 0px 4px;
    top: 0;
    margin: 0;
    border-right: 1px solid $unilever-white;
    background-color: $unilever-dark-blue;
    display: flex;
    z-index: 11;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // margin-left:-10px;
    .url-link {
        text-decoration: none;
        
    }
    .sidebar-header {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding:0px 10px 0px 17px; 
        
        .sidebar-header-edith-icon {
            width: 79px;
            height: 30px;
        }
    }
    
    .sidebar-menu-container {
        display: flex;
        width: 100%;
        margin-top: 54px;
        align-items: flex-start;
        justify-content: center;
        padding: 0px 12px 0px 15px;
        flex-direction: column;
    
    }
    
    .active-container {
        width: 100%;
        display:flex;
        flex-direction: row;
        padding:0px;
        // background-color: green;
    
        &.active {
            border-right: 3px solid $unilever-white;
            // background-color: blue;
        
        }
        .sidebar-menu-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 16px;
            width:100%;
            padding-top: 6px;
            height: 44px;
            // padding: 12px 0px 12px 10px;
            padding:0px 30px 0px 10px;
            color: white;
            // background-color: green;
        
            .icon {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                img {
                    width: 20px;
                    height:20px;
                }
            }
        
            .text {
                display: flex;
                // width: 100px;
                justify-content: flex-start;
                align-items: center;
                
                p {
                    font-family: 'unilever-shilling';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    margin-top: 16px;
        
                }
            }
        
            &.active {
                background-color: $unilever-white;
                color: $grey;
                // margin-right:30px;
            
                padding:0px 20px 0px 10px;
                width:90%;
                // border-right: 2px solid $unilever-white;
                font-weight: 700;
                img {
                    height:20px;
                    width: 20px;
                }
        
                p {
                    font-style: normal;
                    font-family: 'unilever-shilling-medium';
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.26px;
                    color: #73706E;
                }
                
        
            }
            .marker {
                height: 42px;
                border-right: 3px solid $nav-btn-border-pink;
                margin-left:50px;
                // padding-top:17px;
                
            }
            
        }
        
    
    }
    
  

    .currentlogin-container {
        position: absolute;
        bottom:100px;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        margin-top: 140px;
        justify-content: center;
    
        background-color: rgba(249, 249, 249, 0.1);
        // opacity: 0.1;
        height: 68px;
        padding-block: 16px;
        
        
    .rowDiv {
        // background-color: red;
        width: 100%;
        gap:14px;
        display: inline-flex;
        padding-inline-start: 15px;

    }
    img {
        margin-top:-15px;
    }
    .text-container {
        display: flex;
        flex-direction: column;
        // background-color: green;
        justify-content: center;
        // padding-block-start:23px;
        
    }

        p {
            font-family: 'unilever-shilling';
            font-size: 12px;
            
            line-height: 16px;
            color: $unilever-white;
            // margin-top: 14px;
        }
        h2 {
            font-family: 'unilever-shilling';
            font-size: 12px;
            
            line-height: 16px;
            color: $unilever-white;
            margin-top: -12px;
        }
    
    
    }
}

