@import 'src/styles/main';

table {
  border-spacing: 0;
  border: 1px solid #ededed;
  margin: 10px 0px;
  margin-bottom: 0px;
  overflow-x: scroll;
}

table tr:last-child td {
  border-bottom: 0;
}

table th {
  background-color: $table-header;
  padding: 10px 16px 10px 16px;
  font-weight: 400;
  line-height: 20px;
  color: $table-header-font;
}

table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid $table-border;
  position: relative;
  font-family: 'unilever-shilling';
  font-weight: 400;
}

table th:last-child,
table td:last-child {
  border-right: 0;
}

table tr {
  background-color: #fafafa;
}

table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: '';
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
}

table th.sort-asc::before {
  border-bottom: 5px solid #22543d;
}

table th.sort-desc::before {
  border-top: 5px solid #22543d;
}

.App {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.badge {
  background-color: #9ae6b4;
  color: #22543d;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 4px;
  font-weight: bold;
  text-transform: uppercase;
}

.table-component {
  input {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: none;
  }

  thead tr th {
    background-color: $table-empty-cell-color;
  }

  thead tr:first-child th {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.26px;
    line-height: 16px;

    &.data-exist {
      vertical-align: middle !important;
    }
  }

  thead tr th {
    font-weight: 600;
    font-size: 16px;
    
  }

  td {
    border: 1px solid $table-border;
  }

  tbody tr td {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $table-body-font-color;
    padding: 10px 16px;
  }

  tbody .checkbox {
    margin: auto;
  }

  thead tr:nth-child(2) th {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    opacity: 0.7;
  }
}

tbody tr:hover {
  background-color: #effcf5;
}

.table-container:has(.table-component) {
  transform: rotateX(180deg);

  .table-component {
    transform: rotateX(180deg);
    overflow-x: scroll;
  }
}
.table-component-highMedium {
  input {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: none;
  }

  thead tr th {
    background-color: $table-empty-cell-color;
  }

  thead tr:first-child th {
    color: #000000;
    font-weight: 600;
    font-size: 16px;

    &.data-exist {
      vertical-align: top;
      border: 1px solid $table-border;
      border-bottom: 2px solid $table-border;
    }
  }

  thead tr th {
    font-weight: 600;
    font-size: 16px;
    border: 1px solid $table-border;
  }

  td {
    border: 1px solid $table-border;
  }

  tbody tr td {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $table-body-font-color;
    padding: 10px 16px;
  }

  tbody .checkbox {
    margin: auto;
  }
}

tbody tr:hover {
  background-color: #d7f3e6ed;
}


//sidebar expand column 

// .table-component-highMedium th:last-child,
// .table-component-highMedium td:last-child {
//   position: sticky;
//   right: 0;
//   z-index: 1;
//   background-color: #ffffff; /* add a background color to the fixed column to make it distinct */
// }
// .table-component-highMedium th:last-child {
//   background-color: #f0f1fa;
// }
