@import "src/styles/main";

.tab-container-vertical {
  .tab-menu {
    // background-color: red;
  }
  .nav-item {
    // background-color: red;
    color:$unilever-disabled-grey;
    border-bottom: 1px solid $unilever-disabled-grey;
    .nav-link {
      color:$grey-shade-1;
     
    }
       
    .nav-link.active {
      color:$primary-color;
      font-weight: bold;
  }
  }
 

}
