@import "src/styles/variables";

.card-template {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // max-width: 365px;
    // min-width: 365px;
    //   margin: 0px;
    // margin-block-end:28px;
    // margin-inline-end: 28px;
    flex-wrap: wrap;
    border-radius: 0px;
    border: 0;
    cursor: pointer;
    box-shadow: -10px 10px 20px 0px rgba(30, 30, 30, 0.05);

    .card-template-header {
        display: inline-flex;
        justify-content: space-between;
        background: $unilever-white;
        color: $main-text-black;
        font-style: normal;
        font-family: 'unilever-shilling-medium';
        margin-top: 10px;
        font-size: 18px;
        line-height: 9.73px;
        border-bottom: 0;
        padding-bottom: 0px;

        .card-template-heading {
            color: $main-text-black;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            opacity: 0.7;
        }

        .card-template-header-left-icon {
            float: right;
            margin-top: -8px;
        }
    }

    .card-template-body {
        // padding-top: 8px;
        // background-color: red;

        .card-template-stack {
            display: flex;
            justify-content: space-between;
            font-family: 'unilever-shilling', sans-serif;
            position: relative;

            .chart-container {
                width: 100px;
                height: 100px;
            }

            img {
                position: absolute;
                bottom: 0px;
                right: 12px;
            }

            .card-template-image {
                width: 83.33px;
                height: 83.33px;
                opacity: .2;
            }

            .card-template-title {
                font-style: normal;
                // font-weight: 900;
                font-size: 12px;
                // line-height: 56px;
                // background-color: green;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #73706E;

                // max-height: 20px;
                .total-container {
                    //   max-height:20px;
                    margin-top: 9px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-self: center;

                    h2 {
                        font-size: 12px;
                        // font-family: 'unilever-shilling-medium';
                        font-weight: 400;
                    }

                    p {
                        // font-family: 'unilever-shilling-medium';
                        font-size: 12px;
                        margin-top: -25px;
                        font-weight: 600;
                        color: $main-text-black;
                    }
                }
            }
        }
    }

    .card-template-footer {
        width: 100%;
        display: inline-flex;
        flex-wrap: nowrap;
        margin-top: -20px;
        background: $unilever-white;
        color: $grey-shade-1;
        border-top: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 8.73px;
        padding-bottom: 14px;
        padding-inline-start: 18px;

        .footer-item {
            width: 88px;
            border-right: 1px solid $table-border;
            padding-inline-start: 12px;

            // padding-block:4px;
            // background-color: red;
            p:first-child {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                color: #73706E;
                margin-bottom: 10px;

            }

            p {
                margin-bottom: 0px;
            }
        }

        .row .footer-item {
            border-right: 1px solid #e5e5e5;

            // background-color:red;
            &:first-child {
                max-width: 88px;
            }

            p {
                font-style: normal;
                font-family: 'unilever-shilling';
                font-size: 12px;
                line-height: 17px;
            }

            p:first-child {
                font-weight: 400;
                color: #73706E;
                // background-color: green;
                width: 70px;
                // margin-bottom: 2px;
            }

            p:last-child {
                font-weight: 600;
                color: #B4B4B4;
                // color: $failed-tag;
                &.red-flag {
                    color: $failed-tag;
                }
                &.green-flag {
                    color: $success-tag;
                }
            }

            &:last-child {
                border-right: none;

            }
            
        }

    }
}

.separator {
    width: 1px;
    height: 36px;
}