@import "src/styles/variables";

.role-selection-flow {

    .role-selection-container {
        background-color: $light-bg;
        min-height: 100vh;
    }

    padding: 30px;
    background-color: $unilever-white;

    .logo-text {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.2px;
        color: $main-text-black;
    }

    .welcome-message {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 16px;
        color: $main-text-black;
    }

    .welcome-info {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: $grey-shade-1;
    }

    .role-header-title {
        margin-top: 80px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 16px;
        text-align: center;
        color: $main-text-black;
        opacity: 0.7;

    }

    .role-content-options {
        button {
            margin-right: 0.75rem !important;
            margin-left: 0.75rem !important;
            padding: 14px 24px;
            margin-top: 50px;
        }
    }

    .divider-section {
        margin-top: 50px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        opacity: 0.2;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    }

    .avatar-header {
        margin-top: 50px;
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 16px;
        text-align: center;
        color: $main-text-black;
        opacity: 0.7;
    }

    .role-image-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px;

        img {
            margin: 10px;
            cursor: pointer;
        }

        .role-checkbox-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            input[type='radio'] {
                -webkit-appearance: none;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                outline: none;
                border: 1px solid $primary-color;
            }

            input[type='radio']:before {
                content: '';
                display: block;
                width: 60%;
                height: 60%;
                margin: 20% auto;
                border-radius: 50%;
            }

            input[type="radio"]:checked:before {
                background: $primary-color;

            }

            input[type="radio"]:checked {
                border-color: $primary-color;
            }
        }
    }

    .button-container {
        display: flex;
        justify-content: center;
        padding: 60px 0;

        .secondary {
            background: $unilever-white;
        }

        button {
            margin-left: 10px;
            margin-right: 10px;
            padding: 14px 24px 14px 24px;
        }
    }
}