@import "src/styles/variables";

.work-in-progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        color: $main-text-black;
        opacity: 0.5;
    }
}