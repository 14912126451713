@import "src/styles/main";

.dashboard-container {
  position: relative;
  flex-grow: 1;
  // background-color: green;
  
  .page-content {
    padding:0px;
    width:100%;
    margin: 0px;
    position: absolute;
    top:65px;
    max-width: 100%;
    padding-block-start: 40px;
    padding-inline-start: 230px;
    padding-inline-end: 60px;
    padding-block-end: 61px;
    // background-color: violet;
    min-height: calc(100vh - 65px);
    &.padding-left-190 {
    padding-left: 150px;
    margin-top: 0px;
  }
  }
  
}