@import "src/styles/main";

.tab-component{
    padding: 0px;
    .nav-link {
        height: 34px;
        color: $primary-color;
        font-family:'unilever-shilling';
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        padding: 6px 20px 8px 20px;
        background-color: $unilever-white;
        font-size:13px;
        border-top:1px solid $primary-color;
        border-bottom:1px solid $primary-color;
        border-left:1px solid $primary-color;
        border-right: 0px solid $primary-color; 
        &:last-child{
            border:1px solid $primary-color;
        }
        &:hover {
            border:1px solid $primary-color;
        }
    
    }
    
    
    .nav-link.active {
        background: $unilever-blue;
        font-weight: 600;
        color:$unilever-white;
        border:1px solid $primary-color;
    }
    
    
}
