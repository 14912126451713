@import "src/styles/variables";


.loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader-wrapper {
        border: 1px solid #F7F7F7;
        width: 100px;
        height: 100px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        color: #F7F7F7;
    }

    .loader {
        border: 10px solid #E8EAF0;
        border-radius: 50%;
        border-top: 10px solid $unilever-hover-blue;
        border-left: 10px solid $unilever-hover-blue;
        border-right: 10px solid $unilever-hover-blue;
        // border-bottom: 10px solid $unilever-hover-blue;
        width: 65px;
        height: 65px;
        -webkit-animation: spin 2s linear infinite;
        /* Safari */
        animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}