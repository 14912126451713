@import "src/styles/variables";

.high-medium-record {
  .table-component {
    thead tr:nth-child(1) {
      th:nth-child(1) {
        background-color: rgba(0, 31, 130, 0.1);
      }

      th:nth-child(2) {
        background-color: $table-header-group-data-cell-color;
        color: $unilever-white;
      }
    }

    thead tr:nth-child(2) {

      th:nth-child(1),
      th:nth-child(2),
      th:nth-child(3),
      th:nth-child(4),
      th:nth-child(5) {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        opacity: 0.7;
        border-left: 1px solid $table-border;
      }
    }
  }

  .checkbox-container-market{
    padding: 10px 20px;
    padding-left: 0px;
  }

  .table-container{
    overflow-x: auto;
  }
}
.sidebar {
  width: 60%;
  height: 100%;
  background-color: #d7f3e6;
  z-index: 5;
}

.sidebar-root {
  -webkit-transition: all 400ms cubic-bezier(0.79, 0.33, 0.14, 0.53);
  transition: all 400ms cubic-bezier(0.79, 0.33, 0.14, 0.53);
}
