@import "src/styles/variables";

.file-harmonization-status {
    &.card-container {
        padding-block-start: 24px;
        padding-block-end: 33px;
        padding-inline-start: 22px;
        padding-inline-end: 22px;
        box-shadow: -10px 10px 20px 0px rgba(30, 30, 30, 0.05);
        background-color: #FFFFFF;
    }

    .card-title-container {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: $main-text-black;
        opacity: 0.7;

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: #000000;
        }
    }

    .card-title-container-status {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: $grey-shade-1;
        opacity: 0.8;
    }
}