@import 'src/styles/variables';

.dq-check-page {
  .summary-header {
    background-color: $table-header;
    padding: 10px 16px;

    .header-name {
      line-height: 12px;
      font-size: 14px;
      font-weight: 600;
    }

    .header-value {
      margin-top: 6px;
      line-height: 18px;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .skelton-loading {
    width: 60%;
  }
}
