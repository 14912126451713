@import 'src/styles/main';

.navbar-template {
  position: fixed;
  top: 0;
  left: 202px;
  background: $unilever-dark-blue;
  padding: 10px 30px 9px 29px;
  height: fit-content;
  margin: 0px;
  width: 100%;
  justify-content: center;
  align-items: center;
  // padding-left: 193px;
  z-index: 10;
  .navbar-search {
    // background-color: red;
    // padding-block: 3px;
    padding-inline: 0px;
    margin: 0px;

    .search {
      width: 130%;
      max-width: 440px;
      // margin-inline-start: 47px;

      .search-input-field {
        .card-template-header-left-icon {
          top: 14px;
        }
        .search-input-field-text {
          height: 40px !important;
        }
      }
    }
  }

  .container-center {
    // background-color: green;
    display: inline-flex;
    // padding-inline-start: 0px;
    justify-content: flex-end;
    padding-inline: 0px;
    .zoom-controls-container {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      margin-right: 4px;
      gap: 6px;
      padding-block: 2px;
      // background-color: yellow;

      .reset-zoom {
        padding: 6px 12px;
      }
    }
    .nav-dropdown-container {
      background-color: transparent;
      border: 0px;
      margin-left: 24px;
      // margin-right: 13px;
      padding: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: auto;
    }
    .screen-reader-access {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 109.8%;
      color: #f5f5f5;
      .separator {
        width: 1px;
        height: 20px;
        margin-inline: 13px;
        border: 1px solid $border-color-grey;
      }
      p {
        margin-top: 12px;
      }
    }
  }
  .container-right {
    padding: 0px;
    margin: 0px;
    height: 100%;
    padding-block: 8px;
    padding-inline: 0px;
    // background-color: green;
    .icon-container {
      display: flex;
      // justify-content: flex-end;
      width: 80%;
      gap: 27px;
      justify-content: flex-start;
      // background-color: red;
    }
    .profile-pic-wrapper {
      .profile-pic {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 1202px) {
  .container-center {
    margin-inline-start: -38px;
  }
}
