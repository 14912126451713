@import "src/styles/main";
.mainDiv {
    display: flex;
    flex-direction: row;
    .leftDiv{
        width: 20%;
        background: #09a75e;
        border-right: 1px solid white;
    }
    .rightDiv{
        width: 80%;
        
    }
    .rowDiv {
        display:flex;
        justify-content: flex-start;
        align-items: center;
        width: 20%;
        gap: 8px;
        padding:8px;
        margin-bottom: 8px;
        // background-color: $unilever-dark-blue;
    }
    
    .dashboard {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:12px 0px 12px 0px;
        padding:1x;
        background: #F5F5F5;
    }
    .filter-container {
        width:50%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    
}

