@import 'src/styles/variables';

.volume-of-data-processed {
  min-height: 28rem;
  .area-chart-container {
    border-right: 1px solid #e5e5e5;
  }

  .right-container {
    li {
      color: $grey-shade-1;
      font-size: 14px;
    }

    .data-provider-heading {
      font-weight: 600;
      color: $grey-shade-1;
      opacity: 0.8;
    }
  }
  .recharts-cartesian-axis {
    .recharts-cartesian-axis-ticks {
      .recharts-cartesian-axis-tick {
        .recharts-cartesian-axis-tick-value {
          font-size: 0.7rem;
        }
        .recharts-cartesian-axis-tick-line {
          display: none;
        }
      }
    }
  }
  .recharts-yAxis {
    .recharts-label,
    .recharts-text {
      font-size: 0.75rem;
      font-weight: bold;
    }
  }
  .recharts-xAxis {
    .recharts-label,
    .recharts-text {
      font-size: 0.85rem;
    }
  }
  .data-providers {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 0;
    .data-provider {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      &-dot {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
      }
      &-name {
        list-style: none;
        font-size: 0.9rem;
        color: $tab-font-grey;
        letter-spacing: 0.1px;
      }
      .dot-0 {
        background: $data-provider-1;
      }
      .dot-1 {
        background: $data-provider-2;
      }
      .dot-2 {
        background: $data-provider-3;
      }
    }
  }
  .data-provider-title {
    font-size: 0.8rem;
    color: $tab-font-grey;
    font-weight: bold;
    margin: 0;
    height: fit-content;
  }
  .data-provider-total-count {
    font-size: 1.25rem;
    color: $table-body-font-color;
    margin: 0;
    font-family: 'unilever-shilling-bold';
  }
  .data-provider-total-req {
    font-size: 0.85rem;
    color: $tab-font-grey;
    font-weight: 100;
  }
  .recharts-surface {
    height: 24rem;
  }
}
