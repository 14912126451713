@import 'src/styles/main';

.container-mapped {
  margin-top: 30px;
  width: 100%;
  padding: 0px;
  margin: 0px;

  .search-container {
    display: flex;
    justify-content: flex-end;
  }

  .table-container {
    width: 100%;
    padding: 0px;
    // max-width: 84vmax;
  }

  .checkbox {
    width: 22.5px;
    height: 22.5px;
    border-radius: 2px;
    background-color: $unilever-white;
    border: 2px solid $checkbox-border;

    &.header-checkbox {
      background-color: red;
    }
  }
  .table-container-high {
    overflow-x: scroll;
    padding: 0%;
  }
  .table-container-scroll-top {
    transform: rotateX(180deg);
    margin-top: 20px;
    
  .table-component{
    transform: rotateX(180deg);
    overflow-x: scroll;
  }
  }
}

.sidebar {
  width: 60%;
  height: 100%;
  background-color: #d7f3e6;
  z-index: 5;
}

.sidebar-root {
  -webkit-transition: all 400ms cubic-bezier(0.79, 0.33, 0.14, 0.53);
  transition: all 400ms cubic-bezier(0.79, 0.33, 0.14, 0.53);
}
