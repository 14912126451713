@import 'src/styles/main';

.card-template {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // max-width: 365px;
  // min-width: 365px;
  margin: 0px;
  margin-block-end: 28px;
  // margin-inline-end: 28px;
  border-radius: 0px;
  border: 0;
  cursor: pointer;
  box-shadow: -10px 10px 20px 0px rgba(30, 30, 30, 0.05);
  p {
    color: $primary-color;
  }
  .card-template-header-general {
    display: inline-flex;
    justify-content: space-between;
    background: $unilever-white;
    color: $unilever-blue;
    font-style: normal;
    font-weight: 600;
    margin-top: 10px;
    font-family: 'unilever-shilling-medium', sans-serif;
    font-size: 18px;
    line-height: 9.73px;

    border-bottom: 0;
    padding-bottom: 0px;
    // background-color: green;
    p {
      color: $main-text-black;
      opacity: 0.7;
    }
    .card-template-header-left-icon {
      float: right;
      // top:2px;
      // margin-top: 6px;
    }
  }

  .card-template-body {
    padding-top: 8px;
    // background-color: red;
    .card-template-stack {
      justify-content: space-between;
      font-family: 'unilever-shilling', sans-serif;
      position: relative;
      img {
        position: absolute;
        bottom: 0px;
        right: 12px;
      }

      .card-template-image {
        width: 83.33px;
        height: 83.33px;
        opacity: 0.2;
      }

      .card-template-title {
        font-style: normal;
        font-weight: 900;

        p {
          font-family: 'unilever-shilling-bold';
          font-size: 40px;
          line-height: 56px;
          color: #73706e;
        }
      }
    }
    .total-value-loading {
      margin: 1rem 0 1.25rem;
    }
  }

  .card-template-footer {
    width: 80%;
    margin-top: -20px;
    background: $unilever-white;
    color: $grey-shade-1;
    border-top: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 8.73px;
    padding-bottom: 14px;
    padding-inline-start: 18px;

    .footer-item {
      border-right: 1px solid $table-border;
      padding-inline-start: 6px;
      padding-block: 4px;
      // background-color: red;
      p:first-child {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #73706e;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 0px;
      }

      .footer-value-loading {
        margin-bottom: 0.25rem;
      }
    }

    .row .footer-item {
      border-right: 1px solid #e5e5e5 p {
        font-style: normal;
        font-size: 12px;
        line-height: 17px;
      }

      p:first-child {
        font-weight: 400;
        color: #73706e;
        // margin-bottom: 2px;
      }

      p:last-child {
        font-weight: 600;
        color: #b4b4b4;
      }

      &:last-child {
        border-right: none;
      }

      .failed-flag {
        color: $failed-tag;
      }

      .failed-flag {
        color: $failed-tag;
      }
    }
  }
}

.separator {
  width: 1px;
  height: 36px;
}
