@import 'src/styles/variables';

.search-dropdown-container-product {
  width: 100%;
  min-width: 400px;
  border: 1px solid $unilever-disabled-grey;
  background-color: $unilever-white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 6px;
  gap: 12px;

  .label-container {
    display: flex;
    padding-block-start: 10px;
    justify-content: flex-start;
    align-items: center;
    padding-left: 12px;

    p {
      white-space: nowrap;
      font-family: 'unilever-shilling-medium';
      margin-bottom: 11px;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .value-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px;
    padding-block-start: 3px;
    padding-inline-start: 2px;
    margin-left: -10px;
    overflow-x: hidden;
    flex-grow: 1;
    padding-left: 12px;
    h2 {
      font-size: 12px;
      font-family: 'unilever-shilling';
      color: $grey-shade-1;
      padding-top: 5px;
    }
  }
  .toggle-container {
    width: 100%;
  }
  .toggle-menu {
    position: absolute;
    top: 00px;
    width: 100%;
    border-radius: 0px;
    padding-inline: 12px;
    max-height: 250px;
    overflow-y: scroll;
    margin: 0px;
    margin-inline-start: -5px;
    margin-block-start: 3px;

    .dropdown-item {
      white-space: pre-wrap;
    }

    .dropdown-item:hover {
      background: $table-header;
    }
  }
  .search-field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    border: 0px;
    border-radius: 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
  .toggle-button {
    width: 100%;
    position: relative;
    height: 100%;
    align-items: center;
    color: $grey-shade-1;
    margin: 0px;
    display: flex;
    text-decoration: none;
    color: $main-text-black;
    justify-content: space-between;
    .dd-separator {
      width: 1px;
      height: 36px;
      border-color: 1px solid red;
      position: absolute;
      top: 8px;
      right: 26px;
    }

    .dd-cross-container {
      padding: 8px;
      position: relative;
      margin-left: 15px;
      padding-left: 12px;
    }
    .dd-cross {
      position: absolute;
      top: 2px;
      background-color: white;
      right: 10px;
      width: 10px;
      height: 10px;
    }
    .dd-down {
      position: absolute;
      width: 12px;
      height: 7px;

      right: -10px;
    }
    img {
      width: 11px;
      height: 6.6px;
      margin-right: 12px;
      position: absolute;
      top: 18.18px;
      right: 8px;
      margin-left: 10px;
    }
  }
}

.disabled{
  pointer-events: none;
  background-color: rgba(167, 163, 163, 0.11);
  
}

.disabled:hover{
  cursor: not-allowed;
}
