@import 'src/styles/variables';

.validation-summary {
  .page-header-row {
    width: 100%;
    margin: 0px;
  }

  .breadcrumb-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .page-title {
    margin-bottom: 10px;
  }

  .filters-container {
    display: flex;
    align-items: center;
    margin: 0px;
    gap: 12px;
    justify-content: flex-end;

    .filter-text {
      margin-top: 8px;
      font-size: 12px;
      padding-right: 3px;
    }

    .filter-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
  }

  .tab-container {
    border-bottom: 1px solid $unilever-disabled-grey;

    button {
      border-radius: 0px;
      border: 1px solid $primary-color;
      color: $primary-color;
      background: transparent;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;

      &:hover {
        color: white;
        background-color: $primary-color;
      }

      &.active {
        color: white;
        background-color: $primary-color;
      }
    }

  }

}