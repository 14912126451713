@import "src/styles/main";

.option-text {
    font-family: 'unilever-shilling';
    font-size: 11px;
    font-weight: 300;
    color: $dropdown-font;
}

.dropdown-styling {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid $unilever-disabled-grey;
    background-image: url('../../../assets/icons/dropdown-icon.svg');
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 15px;
    padding-left: 12px;
    outline: none;
    padding-right: 1.2rem;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $grey-shade-1;
}