@import "src/styles/main";

.data-logs-container {
  overflow-y: scroll;
  .content-col {
    display: flex;
    flex-direction: column;
    // justify-content: flex-start;
    align-items: flex-start;
    // padding-top:90px;
    .content-div {
      margin-bottom:50px;
    }
    h2 {
      color:$grey-shade-1;
    }
    p {
      font-family: 'unilever-shilling';
      font-size: 14px;
      text-align: justify;
      color: $grey-shade-1;
    }

  }
  .image-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}