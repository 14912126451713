@import 'src/styles/main';

.header {
  margin: 0rem 1rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
}

.row-description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header p {
  font-weight: 700;
}

.header button {
  border: none;
  background-color: transparent;
  padding: 0.5rem;
  border-radius: 50%;
  height: fit-content;
  line-height: 0;
}

.header button:hover {
  background-color: rgba(107, 107, 107, 0.199);
}

.data {
  padding: 0 0 0.5rem 0;
  font-size: 14px;
}

.data-header .header {
  font-size: 1rem;
  font-weight: 700;
  padding: 0;
}

.content {
  background-color: white;
  padding: 1rem 3rem;
  min-height: 500px;
  max-height: 985px;
  margin: 0rem 1rem;
  border-radius: 6px;
  box-shadow: 1px 2px 16px rgb(109, 108, 108);
  overflow: auto;
}

.footer {
  text-align: right;
  margin: 1rem 0.5rem;
  height: 80px;
}

.footer Button {
  margin: 0rem 1rem;
}

.footer .submit-button {
  border: 1px solid $primary-color;
}

.footer .cancle-button {
  background-color: transparent;
  border: 1px solid $primary-color;
  color: $primary-color;
}
