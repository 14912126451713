@import "src/styles/variables";

.dropdown-container {
  width:100%;
  min-height: 32px;
  max-height: 32px;
  position: relative;
  border:1px solid $unilever-disabled-grey;
  // background-color: red;
  display: flex;
  align-items: center;
  padding-inline:14px;
  // padding-block: 7px;
  // padding:5px 5px 5px 16px;
  display: flex;
  justify-content: space-between;
 .toggle-container {
  width: 100%;
  background-color: green;
 } 
 .toggle-menu {
  position: absolute;
  top:60px;
  width: 100%;
  border-radius: 0px;
  padding-inline:12px;
  max-height:250px;
  overflow-y: scroll;
  margin:0px;
  margin-inline-start:-17px;
  margin-block-start:3px;
  // padding:8px 0px 8px 0px;
  // background-color: red;
}
.search-field {
  width:100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding:12px;
  border:0px;
  border-radius: 0px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

}
.toggle-button {
  width:100%;
  height: 100%;
  // background-color: red;
  padding:7px 0px 7px 0px;
  color:$grey-shade-1;
  display:flex;
  text-decoration: none;
  color:$main-text-black;
  justify-content: space-between;

  img{
    width: 11px;
    height:6.6px;
    position: absolute;
    top:12.18px;
    right:15px;
    // margin-right: 8px;
  }
}
}
