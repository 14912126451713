@import "src/styles/main";

.sso-container {
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/images/vision_background.webp') ;
  background-repeat: no-repeat;
  background-size: cover;
  // box-shadow: inset 0 0 0 1000px rgba(120,120,120,.42);
  background-color: $bg-color-login;
  .card-container {
    background-color: white;
    border-radius: 8px;
    height:506px;
    width:693px;

    .card-header {
      padding-block:12px;
      // background-color: green;
    }
    .icon-row {
      // background-color: red;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-block-start:35px;
      .icon-row-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-block-start: 37px;
        h2 {
          font-family: 'unilever-shilling-medium';
          font-size: 32px;
          line-height: 16px;
        }
        
        p{
          margin-top: 16px;
        }

        .formSection{
          margin: 1rem 0;
        }
      }
    }
    .btn-container-signin {
      width:100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: pink;

    }
  }

}