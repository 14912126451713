@import "src/styles/main";

.container-mapped {
  margin-top: 30px;
  width: 100%;
  padding: 0px;
  margin: 0px;


  .search-container {
    display: flex;
    justify-content: flex-end;

    .search-input-field {
      .card-template-header-left-icon {
        top: 16px
      }
    }
  }

  .table-container {
    width: 100%;
    padding: 0px;
    // max-width: 84vmax;
  }

  .checkbox {
    width: 22.5px;
    height: 22.5px;
    border-radius: 2px;
    background-color: $unilever-white;
    border: 2px solid $checkbox-border;
  }
}