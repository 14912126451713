/* Color palette */
$primary-color: #09a75e;
$primary-dark-color: #0a7739;
$primary-light-color: #5cb677;
$secondary-green-color: #11532b;
$secondary-yellow-color: #f5be3c;
$main-text-black: #000000;
$grey: #6b6d7d;
$scrollbar-thumb: #8390b7;
$line-seperator: #e8eaf0;
$ternary-color-red: #b90808;
$ternary-color-orange: #fd7b02;
$ternary-color-blue: #3d885b;
$unilever-blue: #09a75e;
$nav-btn-pink: #e2bee8;
$unilever-white: #ffffff;
$grey-shade-1: #73706e;
$pink-shade-2: #fca6de;
$table-header: #d7f3e6;
$table-header-font: #282828;
$unilever-dark-blue: #083a23;
$unilever-hover-blue: #c0f6dd;
$unilever-disabled-grey: #d9d9d9;
$tab-font-grey: #636466;
$dashboard-bg: #f5f5f5;
$table-border-vertical: #979797;
$table-border: #e2e2e2;
$dropdown-font: #576571;
// $filter-dashboard-font:#202224;
//border color
$border-color-grey: #b4b4b4;
$nav-btn-border-pink: #eeeeee;
$data-provider-1: #008651;
$data-provider-2: #ff0a7a;
$data-provider-3: #9c44c0;
$confidence-high: #09a75e;
$confidence-medium: #ff9e00;
$confidence-low: #bdbdbd;

$secondary-dark-green-color: #07391b;
$secondary-light-green-color: #57866a;

// Spacer Variables
$spacer-x: 1rem;
$spacer-y: 1rem;

// Table
$table-border: #e5e5e5;
$table-empty-cell-color: #d7f3e6;
$table-header-data-cell-color: #d7f3e6;
$table-header-group-data-cell-color: #083a234a;
$table-body-font-color: #73706e;
$checkbox-border: #8c9196;
$link-blue: #008651;
$unmapped-active-button: rgba(0, 177, 144, 0.05);
$success-tag: #09a75e;
$failed-tag: #ff544f;
$progress-tag: #ffc000;
$footer-bg: #fcfcfc;

// volatility
$error-bg: #f5f8ff;
$light-bg: #f5f8ff;

// Login Screen
$bg-color-login: #07391b;
