@import "src/styles/main";

.page-container-details {
padding:0px;
margin: 0px;
width: 100%;

.breadcrumb-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.page-heading {
  font-family: 'unilever-shilling';
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 10px;
  line-height: 30px;
}

.tabs-container {
  width:100%;
  padding:0px;
  margin:0px;
}
.databar-container {
  margin-bottom: 30px;
  margin-inline:0px;
  width: 100%;
  background-color: purple;
  margin-top: 10px;
}

}