@import 'variables';
@import 'helpers';

/* General component styles */
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'unilever-shilling';
  // box-sizing: border-box;
  background-color: $dashboard-bg;
}

.page-content {
  // padding: 20px;
}

.page-title {
  font-style: normal;
  font-family: 'unilever-shilling-medium';
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: #000000;
}

.page-sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #73706e;
}

::-webkit-scrollbar {
  opacity: 0;
  width: 4px;
  height: 4px;
  z-index: 2;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb;
}

::-webkit-scrollbar-thumb:horizontal {
  background: $scrollbar-thumb;
  height: 1px;
  border-radius: 10px;
}

::webkit-scrollbar-thumb:hover {
  background: $scrollbar-thumb;
}

.hyper-link,
.cell-hyperlink {
  margin: 0px 8px 0px 3px;
  color: #09a75e;
  text-decoration: underline;
  cursor: pointer;
}

.bg-white {
  background-color: $unilever-white;
}

.tooltip-inner {
  border-radius: 0px;
  padding: 10px;
}

.table-container-scroll {
  overflow-y: scroll;
}

.table-container {
  min-height: 15rem;
  display: flex;
  .checkbox {
    width: 22.5px;
    height: 22.5px;
    border-radius: 2px;
    background-color: $unilever-white;
    border: 2px solid $checkbox-border;
  }
}

// For page filters
.filters-container {
  display: flex;

  flex-direction: row;
  align-items: center;
  margin: 0px;
  gap: 12px;

  .filter-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      font-family: 'unilever-shilling-medium';
    }
  }

  .filters-container-text {
    font-size: 12px;
  }
}

.tab-container {
  border-bottom: 1px solid $unilever-disabled-grey;

  button {
    border-radius: 0px;
    border: 1px solid $primary-color;
    color: $primary-color;
    background: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;

    &:hover {
      color: white;
      background-color: $primary-color;
    }

    &.active {
      color: white;
      background-color: $primary-color;
    }
  }
}

.status-tags {
  padding: 0px 8px 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $unilever-white;
  border-radius: 4px;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;

  &.success {
    background-color: $success-tag;
  }

  &.progress {
    background-color: $progress-tag;
  }

  &.failed {
    background-color: $failed-tag;
  }
}
