@import 'src/styles/main';

.container-mapped {
  padding: 0px;
  margin-inline: 0px;
  margin-top: 25px;

  .load-filters-container {
    display: inline-flex;
    width: 100%;
    justify-content: flex-start;
    gap: 15px;

    .filter-container {
      width: 100%;
      display: flex;
      align-items: center;

      p {
        font-family: 'unilever-shilling-medium';
      }
    }

    &.filter-container-same-line {
      flex: 1;
    }
    
    .input-search-container {
      width: 100%;
    }
  }

  .table-top-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 43px 9px 10px;
    border: 1px solid $unilever-disabled-grey;
    margin: 21px 0px -10px 0px;
    background-color: $unilever-white;

    .right-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;

      p {
        margin: 0px;
        font-family: 'unilever-shilling-medium';
        color: $main-text-black;
        font-size: 14px;
      }
    }

    .separator {
      border-right: 1px solid $unilever-disabled-grey;
      margin: 0px 11px 0px 11px;
    }

    p {
      margin-top: 6px;
    }
  }

  .change-view-btns-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 0px;
    justify-content: flex-end;

    .sort-by-button {
      border: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 37px;
      padding-top: 6px;
      padding-inline-end: 10px;
      padding-inline-start: 10px;
      gap: 8px;

      img {
        margin-left: 11px;
        width: 19px;
        height: 13px;
      }

      .sort-by-button-txt {
        font-size: 14px;
      }
    }

    .sort-container {
      position: relative;

      .sortby-container {
        background-color: $unilever-white;
        width: 200px;
        position: absolute;
        z-index: 2;
        top: 35px;

        .sort-item {
          cursor: pointer;
          height: 40px;
          margin: 0px;
          padding-inline-start: 16px;
          background-color: $unilever-white;
          display: flex;
          vertical-align: middle;
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: $main-text-black;
            margin: 10px 0px;
          }

          &:hover {
            background-color: $table-header;
          }
        }
      }
    }

    p {
      margin-top: 12px;
      margin-right: 12px;
      font-weight: 400;
      color: $grey-shade-1;
    }

    // img {
    //   width: 10px;
    //   height: 10px;
    // }

    .view-information-as-txt {
      font-size: 14px;
    }
    .view-information-as-icon {
      width: 10px;
      height: 10px;
    }
    .btn-container {
      padding: 10px;
      border: 1px solid $primary-color;
    }
  }

  .success-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 35px;
    display: flex;
    justify-content: flex-end;
    gap: 7px;
    .secondary {
      font-size: 13px;
    }
    .pressed {
      font-weight: 600;
    }
    &.small-button {
      .btn-container {
        padding-top: 6px;
        padding-bottom: 8px;
      }
    }
  }

  .success-tag {
    padding: 0px 8px 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $success-tag;
    color: $unilever-white;
    border-radius: 4px;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
  }
  .progress-tag {
    padding: 0px 8px 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $progress-tag;
    color: $unilever-white;
    border-radius: 4px;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
  }

  .failed-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 48px;
    height: 20px;
    padding: 0px 8px 2px 8px;
    background-color: $failed-tag;
    color: $unilever-white;
    border-radius: 4px;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
  }

  .btn-content {
    display: flex;
    justify-content: space-around;
    gap: 5px;
    align-items: center;
    padding: 12px;
    font-size: 14px;
  }

  .btn-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    p {
      margin-top: 12px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $unilever-hover-blue;
    }
  }

  .small-tab-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    justify-content: center;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
    }

    .tab-btn-container {
      display: flex;
      flex-direction: row;
    }
  }

  .table-component {
    width: 100%;

    thead tr th {
      background-color: $table-header;
      padding: 15px;
    }

    tbody tr td:nth-child(6) {
      max-width: 200px;
      word-wrap: break-word;
    }

    thead tr:nth-child(2) {
      th:nth-child(9) {
        border-left: 1px solid $table-border;
      }

      th:nth-child(5),
      th:nth-child(6),
      th:nth-child(7),
      th:nth-child(8),
      th:nth-child(9),
      th:nth-child(10),
      th:nth-child(11) {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    tbody tr td {
      border-left: none;
      border-right: none;
      cursor: pointer;
    }
  }

  .table-container {
    display: flex;
    width: 100%;
    margin: 0px;
    margin-top: 0px;
    margin: 0px;
    background-color: transparent;
    overflow-x: scroll;
    overflow-y: auto;
  }

  .cell-hyperlink {
    color: $link-blue;
    text-decoration: underline;
    cursor: pointer;
  }
}
