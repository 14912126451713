@import "src/styles/variables";

.tasks-due-container {
  width:100%;
  // padding:0px;
  // min-height: 100%;
  // margin: 0px;
  background-color: $unilever-white; 
  // margin-block-start:27px;
  // margin-block-end:27px;
  overflow-y: scroll;
  overflow-x: hidden;
//   &::-webkit-scrollbar-track {
//     background: #73706E; 
//     opacity: 0.2;
//   }
//   &::-webkit-scrollbar-thumb {
//     background: #083a23;
// opacity: 0.4;
//   }
  .tasks-header {
    display: inline-flex;
    width: 100%;
    // padding:0px;
    // margin:0px;
    // background-color: green;
    align-items: center;
    justify-content: space-between;
    .card-title-container{
      width: 100%;
      height: 100%;
      margin-block-start:16px;
      // padding-inline-start:16px;
      // padding-inline-end:13px;
      // background-color: red;
      // padding:0px;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      // margin-block-start:24px;
      // margin-block-end:15px;
      h3 {
        font-family: 'unilever-shilling';
        font-size:18px;
        font-weight: 600;
        color: $main-text-black;
        opacity: 0.7;
      }
    }
    
  }
  .tasks-btn-row {
    display: flex;
    width: 100%;
    gap:11px;
    margin-block-start:16px;
    flex-wrap: wrap;
    // margin-inline-start:16px;
    // background-color: red;
    // padding:0px;
    flex-direction: row;
    .btn-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap:5px;
      img {
        margin-top:-2px;
      }
      
    }
    .status-btn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding-inline:12px;
      font-family: 'unilever-shilling-medium';
      font-size:12px;
      width:63px;
      height: 35px;
      color:$primary-color;
      background-color: $table-header;
      border:2px solid $primary-color;
    }
   
  }
  .list-container {
    margin-block-start:11px;
    // max-height: 365px;
    // max-height:max-content;
    // background-color: red;
    // overflow-y: scroll;
  .list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding-inline:8px;
    padding-top:30px;

    width:100%;
    border-bottom: 1px solid $table-border;
    // background-color: green;
    .first-row {
      display: flex;
      width:100%;
      padding-block: 0px;
      justify-content: space-between;
      align-items: center;
      // padding-top:8px;
      // background-color: red; 
      h3 {
        font-size:14px;
        font-family: 'unilever-shilling';
        font-weight: 600;
        // line-height: 19.52px;
      }
      p {
        font-family: 'unilever-shilling';
        font-size:12px;
        // line-height: 18.95px;
      }
      .success-tag {
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $success-tag;
        color:$unilever-white;
        border-radius: 4px;
        font-weight: 400;
        font-size:10px;
        margin: 0;
        // margin-top:12px;
        // line-height: 18px;
      }
      .failed-tag {
        display: flex;
        justify-content: center;
        align-items: center;
        width:48px;
        height: 20px;
        padding:6px;
        background-color: $failed-tag;
        color:$unilever-white;
        border-radius: 4px;
        font-weight: 400;
        font-size:10px;
        margin: 0;
        // line-height: 18px;
        
      }
    }
    .second-row {
      // background-color:blue;
      display: flex;
      width: 100%;
      margin-top:-8px;
      padding-block:0px;
      p {
        text-align: center;
        font-size:12px;
      }
    }

 
  }
}
}
@media (max-width: 1202px) {
  .list-container {
    height: 260px;
    overflow: auto;
  }
}
