@import "src/styles/main";

.reports-page-content {
  width:100%;
  
  margin:0px;
  padding:0px;
  // overflow-x: hidden;
  // padding:40px 60px 0px 27px;
  background-color:$dashboard-bg;
  .cards-container{
    margin-bottom: 54px;
    // background-color: red;
  }

  .page-title-text{
    margin-bottom: 54px;
    .page-title {
      margin-bottom: 0;
    }
  }

  .card-template  {
    .card-template-header-general {
      padding-top: 0;
      margin-top: 12px;
      line-height: 25px;
    }
  }
}

@media (min-width: 1500px) {
  .cards-container {
    max-width: 1300px;
    // display: flex;
    // flex-wrap: nowrap;
    // height: 260px;
    // overflow: auto;
  }
}
