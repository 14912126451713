@import 'src/styles/variables';

.dimension-details {
  .download-report-image {
    margin-left: 8px;
  }

  .small-button {
    .btn-container {
      padding-top: 6px;
      padding-bottom: 8px;
    }
  }

  .table-action-header {
    border: 1px solid $unilever-disabled-grey;
    margin: 21px 0px -10px 0px;
    background-color: $unilever-white;
    padding: 8px;
  }

  .table-component {

    thead tr:nth-child(2) {
      th:nth-child(5),
      th:nth-child(6),
      th:nth-child(7),
      th:nth-child(8) {
        border-left: 1px solid $table-border;
      }
    }
  }
}
