@import "src/styles/main";

.volatility-mapping {
  // background-color: yellow;
  margin: 0px;
  padding: 0px;

  // padding:0px 0px 0px 40px;
  .top-row {
    margin-top: 0px;
    padding: 0px;

    // margin:0px;
    // background-color: green;
    .breadcrumb-container {

      // background-color: green;
      // padding:0px;
      // margin: 0px;
      .page-header {
        // background-color: red;
        width: 100%;
        // padding:0px;
        // margin:0px;
      }
    }

    .remarks-modal {
      border-radius: 0px;
    }

    .file-name-row {
      // background-color: red;
      display: flex;
      flex-direction: row;
      // padding:0px;
      margin: 5px 0px 40px 0px;

      .file-change-btn {
        cursor: disabled;
      }

      p {
        margin: 0px 0px 0px 8px;
        color: $grey-shade-1;
        font-size: 16px;
        line-height: 16px;
        margin-top: 5px;
        font-family: 'unilever-shilling';
      }

      a {
        margin: 0px 0px 0px 3px;
        font-size: 14px;
        line-height: 14px;
        color: $primary-color
      }
    }

    .menu-container {
      // background-color: red;
    }

    .menu-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // padding:0px;
      margin-top: -10px;

      .menu-item {
        display: flex;
        flex-direction: row;
        margin: 5px 0px 40px 0px;

        .file-change-btn {
          cursor: disabled;
        }

        p {
          margin: 0px 0px 0px 8px;
        }

        a {
          margin: 0px 8px 0px 3px;
          color: $primary-color
        }
      }

      .menu-container {
        // background-color: green;
      }

      .menu-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // background-color: red;

      }

      .left-container {
        max-height: 240px;

        // background-color: green;
        .item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          color: $grey-shade-1;
          // background-color: red;
          padding: 0px;
          gap: 7px;
          padding-block: 0px;
          //  margin-bottom: -10px;
          //  margin-top:2px;
          width: 100%;
          // height:19px;

          .success-tag {
            padding: 0px 8px 2px 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $success-tag;
            color: $unilever-white;
            border-radius: 4px;
            font-weight: 400;
            font-size: 10px;
            line-height: 18px;
          }

          .failed-tag {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 20px;
            padding: 0px 8px 2px 8px;
            background-color: $failed-tag;
            color: $unilever-white;
            border-radius: 4px;
            font-weight: 400;
            font-size: 10px;
            line-height: 18px;

          }

          h2 {
            font-family: 'unilever-shilling-medium';
            font-size: 12px;
            margin: 0px;
            // font-size: bold;

            // line-height: 24px;
          }

          p {
            // margin-top:10px;
            font-weight: 200;
            margin: 0px;
            font-size: 12px;
            line-height: 24px;
          }

          .small-text {
            font-size: 10px;
          }
        }
      }
    }

    .right-container {
      // background-color: blue;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 21px;

      .excel-button {
        background-color: $unilever-white;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;
        padding: 2px 22px 2px 22px;

        p {
          margin-top: 12px;
          margin-right: 8px;
        }

        // padding:6px;
        .arrow-button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 30px;
          // padding:4px 6px 4px 6px;
          margin-left: 2px;
          background-color: $error-bg;
        }

      }

      .remarks-button {
        background-color: $unilever-white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 0px 16px 0px 16px;
        color: $unilever-blue;
        font-family: 'unilever-shilling-medium';

        p {
          margin-top: 13px;
        }
      }
    }

    .middle-container {
      // background-color: green;
      margin: 12px 0px 52px 0px;

      .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        //  background-color: red;
        padding: 0px;
        margin-top: 2px;
        width: 100%;

        h2 {
          font-weight: bold;
          font-size: 14px;
          font-size: bold;
          // line-height: 24px;
        }

        p {
          margin-top: 10px;
          font-weight: 200;
          font-size: 14px;
          // line-height: 24px;
        }
      }
    }

    //  background-color: red;
    .failed-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 20px;
      padding: 0px 8px 2px 8px;
      background-color: $failed-tag;
      color: $unilever-white;
      border-radius: 4px;
      font-weight: 400;
      font-size: 10px;
      line-height: 18px;

      .table-container {
        margin-top: 30px;
        margin-inline: 0px;
        padding: 0px;
        position: relative;

        .last-row-btns {
          display: flex;
          position: absolute;
          top: 300px;
          flex-direction: row;
          justify-content: space-between;
          background-color: $footer-bg;
          // background-color: red;
          padding: 12px 22px 12px 22px;
          gap: 30px;

          .right-btns {
            display: inline-flex;
            gap: 12px;
            // background-color: green;
          }
        }

        background-color: transparent;

        // background-color: red;
        .left-table-container {
          // padding:16px 16px 12px 16px;

          // background-color: red;
          div {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 3px 0px 0px 5px;
            font-family: 'unilever-shilling-medium';
          }

          .header {
            background-color: $table-empty-cell-color;
            display: flex;
            height: 100%;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            // width: 100%;


            .first {
              // background-color: yellow;
            }

            .second {
              // background-color: green;
              display: flex;
              flex-direction: row;
              gap: 8px;

              .col-number {
                border-right: 1px solid $grey-shade-1;
                padding-right: 6px;
                font-family: 'unilever-shilling';
                color: $grey-shade-1;
              }

              .error {
                color: #FF9998;
                display: flex;
                justify-content: center;
                background-color: $error-bg;
                padding: 4px 6px 6px 6px;
                font-weight: 600;
                align-items: center;

                img {
                  margin-top: 4px;
                  margin-right: 5px;
                }
              }
            }
          }

          .right-table-container {
            padding: 16px 0px 16px 16px;
            border-left: 1px solid $table-border;
            background-color: $table-empty-cell-color;

            div {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 2px;
              padding: 2px 22px 2px 22px;

              p {
                margin-top: 12px;
                margin-right: 8px;
              }

              // padding:6px;
              .arrow-button {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                width: 30px;
                // padding:4px 6px 4px 6px;
                margin-left: 2px;
                background-color: $error-bg;
              }

            }

            .remarks-button {
              background-color: $unilever-white;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 8px;
              padding: 0px 16px 0px 16px;
              color: $unilever-blue;
              font-family: 'unilever-shilling-medium';

              p {
                margin-top: 13px;
              }
            }
          }

          .end-table-container {
            padding: 0px;
          }
        }

        .table-content {
          // border:1px solid black;


          padding: 0px;
          margin: 0px;

          .left-col {
            background-color: $unilever-white;
            color: $grey-shade-1;
            // background-color: green;
            margin: 12px 0px 52px 0px;

            .item {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              //  background-color: red;
              padding: 0px;
              margin-top: 2px;
              width: 100%;

              h2 {
                font-weight: bold;
                font-size: 14px;
                font-size: bold;
                // line-height: 24px;
              }

              p {
                margin-top: 10px;
                font-weight: 200;
                font-size: 14px;
                // line-height: 24px;
              }
            }
          }


          .table-container {
            margin-top: 30px;
            background-color: $table-empty-cell-color;

            .left-table-container {
              padding: 16px 16px 12px 16px;

              // background-color: red;
              div {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 3px 0px 0px 5px;
                font-family: 'unilever-shilling-medium';
              }

              .header {
                // background-color: blue;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                // width: 100%;

                .first {
                  // background-color: yellow;
                }

                .second {
                  // background-color: green;
                  display: flex;
                  flex-direction: row;
                  gap: 8px;

                  .col-number {
                    border-right: 1px solid $grey-shade-1;
                    padding-right: 6px;
                    font-family: 'unilever-shilling';
                    color: $grey-shade-1;
                  }

                  .error {
                    color: #FF9998;
                    display: flex;
                    justify-content: center;
                    background-color: $error-bg;
                    padding: 4px 6px 6px 6px;
                    font-weight: 600;
                    align-items: center;

                    img {
                      margin-top: 4px;
                      margin-right: 5px;
                    }
                  }
                }
              }
            }

            .right-table-container {
              padding: 16px 0px 16px 16px;
              border-left: 1px solid $table-border;

              div {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;

                p {
                  margin-top: 12px;
                  font-family: 'unilever-shilling-medium';
                }
              }

              .update-button {
                background-color: $error-bg;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 4px;
                padding: 8px 8px 10px 8px;
                color: $main-text-black;
                // font-family: 'unilever-shilling-medium';

              }
            }

            .end-table-container {
              padding: 0px;
            }
          }

          .table-content {
            // border:1px solid black;
            padding: 0px;
            margin: 0px;

            .left-col {
              background-color: $unilever-white;
              color: $grey-shade-1;

              // background-color: green;
              .item {
                // background-color: red;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 0px 10px 0px;
              }

              .row-item {
                border-bottom: 1px solid $unilever-disabled-grey;
                // background-color: yellow;
                padding: 4px 24px 0px 30px;
              }
            }

            .right-col {
              // background-color: red;
              border-left: 1px solid $unilever-disabled-grey;

              .row-item {
                border-bottom: 1px solid $unilever-disabled-grey;
                background-color: red;
                // padding:0px;
              }
            }
          }

          .last-row-btns {
            display: flex;
            width: 100%;
            padding: 0px;
            flex-direction: row;
            justify-content: flex-end;
            background-color: $unilever-white;
            padding: 12px 12px 12px 0px;
            gap: 30px;
          }

          .dropdown-container a {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #73706E;
          }

          .source-header,
          .target-header {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: 0.26px;
            opacity: 0.7;
            color: #000000;
          }

          .update-action {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 0.2px;
            color: #000000;
          }

        }
      }
    }
  }

  .error-tag .dropdown-container{
    background: #FFEBEA;
    border: 1px solid #D9D9D9;
  }

}