@import "src/styles/main";

.container-unmapped {
  // margin-top:30px;
  width: 100%;
  // background-color:red;


  .header-row {
    background-color: $unilever-white;
  }

  .unmapped-items-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 0px 10px 30px;
    border-bottom: 1px solid $unilever-disabled-grey;
    border-right: 1px solid $unilever-disabled-grey;

    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 34px;
      margin-right: 10px;
      background-color: $table-header;
      color: $primary-color;
      font-style: normal;
      font-weight: 600;
    }

    .heading-container {
      display: flex;
      // background-color: blue;

      margin-top: 24px;
      // flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    h2 {
      font-family: 'unilever-shilling';
      font-weight: 600;
      font-size: 16px;
      line-height: 24.32px;
    }

    p {
      font-family: 'unilever-shilling';
      font-weight: 400;
      font-size: 12px;
      line-height: 14.46px;
      color: $main-text-black;
    }
  }

  .nearest-match-header {
    // background-color: yellow;
    display: flex;
    flex-direction: row;
    // justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid $unilever-disabled-grey;

    p {
      font-family: 'unilever-shilling';
      font-weight: 600;
    }

    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 34px;
      margin-right: 10px;
      background-color: $table-header;
      font-style: normal;
      font-weight: 600;
      color: #09a75e;

      &.disabled {
        color: $unilever-disabled-grey;
      }
    }

    .heading-container {
      // background-color: green;
      display: flex;
      justify-content: flex-start;
      // padding:0px 0px 0px 24px;
    }

    .search-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      // background-color: red;
    }
  }

  .list-container {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    border-right: 1px solid $unilever-disabled-grey;
    // padding:220px 0px 40px 0px;
    max-height: 500px;
    overflow-y: scroll;
    // background-color: red;

  }

  .list-item-container {
    display: block !important;
    justify-content: space-around;
    align-items: flex-start;
    border: 1px solid $unilever-disabled-grey;
    padding: 12px 15px 10px 15px;
    margin: 12px 12px 0px 18px;
    background-color: transparent;
    font-weight: 500;
    font-size: 13px;
    color: $table-body-font-color;
    text-align: left;

    &.active,
    &.selected {
      background-color: $unmapped-active-button ;
    }

    &.active {
      border: 1px solid $primary-color !important;
    }

  }

  // span Area
  .span-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // gap:2px;
    p {
      width: 40%;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      word-wrap: normal;
      color: $main-text-black;
      // background-color: red;
      text-align: center;
    }

  }

  .footer-unmapped {
    display: flex;
    padding: 15px 0px 15px 0px;
    width: 100%;
    // background-color: red;
    justify-content: flex-end;
    gap: 10px;
  }

  .table-container-unmapped {
    max-width: 57vmax;
    max-height: 34vmax;
    // padding:12px 20px 12px 12px;
    width: 70vmax;
    overflow-y: scroll;
    overflow-x: scroll;
  }

  .radio {
    width: 20px;
    height: 20px;
  }


  .table-component th {
    vertical-align: middle !important;
  }

  .suggestion-record-container {
    flex-direction: row !important;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between !important;

    .count-header {
      p {
        font-weight: 600;
        font-size: 16px;
        color: $main-text-black;
      }

      span {
        font-weight: 400;
        font-size: 12px;
        color: $main-text-black;
        opacity: 0.7;
      }
    }

  }



}