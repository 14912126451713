@import "src/styles/main";

.page-container-details {
padding:0px;
margin: 0px;
width: 100%;
// background-color: olivedrab;

.breadcrumb-container {
  display: flex;
  flex-direction: column;
  // background-color: red;
  width: 100%;
}
.page-heading {
  font-family: 'unilever-shilling';
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 10px;
  line-height: 30px;
}

.tabs-container {
  width:100%;
  padding:0px;
  margin:0px;
  // background-color: orange;
  
  
}
.databar-container {
  margin-bottom: 30px;
  margin-inline:0px;
  width: 100%;
  background-color: purple;
  margin-top: 10px;
}
.file-name-row {
  display: flex;
  flex-direction: row;
  margin: 5px 0px 30px 0px;
  .file-change-btn {
    cursor: disabled;
  }
  p {
    margin: 0px 0px 0px 8px;
    color: $grey-shade-1;
    font-size: 16px;
    line-height: 16px;
    margin-top: 5px;
    font-family: 'unilever-shilling';
  }
  a {
    margin: 0px 0px 0px 3px;
    font-size: 14px;
    line-height: 14px;
    color: $primary-color;
  }
}
}