@import 'src/styles/main';

.container-unmapped {
  width: 100%;

  .unmapped-items-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 0px 10px 30px;
    border-bottom: 1px solid $unilever-disabled-grey;
    border-right: 1px solid $unilever-disabled-grey;
    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 34px;
      margin-right: 10px;
      background-color: $table-header;
      color: $primary-color;
      font-style: normal;
      font-weight: 600;
    }
    .heading-container {
      display: flex;

      margin-top: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    h2 {
      font-family: 'unilever-shilling';
      font-weight: 600;
      font-size: 16px;
      line-height: 24.32px;
    }
    p {
      font-family: 'unilever-shilling';
      font-weight: 400;
      font-size: 12px;
      line-height: 14.46px;
      color: $main-text-black;
    }
  }
  .nearest-match-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $unilever-disabled-grey;
    h2 {
      font-family: 'unilever-shilling';
      font-weight: 600;
      font-size: 16px;
      line-height: 24.32px;
    }
    p {
      font-family: 'unilever-shilling';
      font-weight: 400;
      font-size: 12px;
      line-height: 14.46px;
      color: $main-text-black;
    }

    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 34px;
      margin-right: 10px;
      background-color: $table-header;
      font-style: normal;
      font-weight: 600;
      color: #09a75e;
      &.disabled {
        color: $unilever-disabled-grey;
      }
    }
    .heading-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .search-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }

  .list-container {
    display: flex;
    flex-direction: column;
    border-right: 1px solid $unilever-disabled-grey;
    max-height: 500px;
    overflow-y: scroll;
  }
  .list-item-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    border: 1px solid $unilever-disabled-grey;
    padding: 12px 15px 10px 15px;
    margin: 12px 12px 0px 18px;
    background-color: transparent;
    font-weight: 500;
    font-size: 13px;
    color: $table-body-font-color;
    text-align: left;
    &.active {
      background-color: $unmapped-active-button;
      border: 1px solid $unilever-disabled-grey;
    }
  }

  // span Area
  .span-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      width: 40%;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      word-wrap: normal;
      color: $main-text-black;
      text-align: center;
    }
  }
  .footer-unmapped {
    display: flex;
    padding: 15px 0px 15px 0px;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
  }
  .table-container-low {
    max-width: 57vmax;
    width: 70vmax;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .radio {
    width: 20px;
    height: 20px;
  }
}
.submit-button,.submit-button:disabled{
  background-color: #083a23;
  border: 1px solid #083a23;
}
.submit-button:hover{
  cursor: pointer;
}

