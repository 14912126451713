@import 'src/styles/main';
.area-chart-card {
  min-height: 32rem;
  padding-block-start: 24px;
  padding-block-end: 30px;
  padding-inline-start: 22px;
  padding-inline-end: 22px;
  margin-block-start: 31px;
  // margin-inline:0px;
  box-shadow: -10px 10px 20px 0px rgba(30, 30, 30, 0.05);
  background-color: $unilever-white;
  // background-color: green;

  .card-title-container {
    // background-color: red;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    // margin-block-start:24px;
    margin-block-end: 15px;
    .heading-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      p {
        font-size: 14px;
        color: $grey-shade-1;
      }

      .heading-container-text {
        font-weight: 600;
        margin-bottom: 10px;
        opacity: 0.7;
      }
    }
  }
  .card-dropdown-container {
    // background-color: green;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-block-end: 31px;
    h2 {
      font-family: 'unilever-shilling-medium';
      color: $primary-color;
      font-size: 12px;
      // line-height: 22px;
      margin-top: 5px;
    }
    .download-container {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      // background-color: red;
      padding-inline-end: 38px;
      gap: 5px;
    }
  }
  h3 {
    font-family: 'unilever-shilling-medium';
    font-size: 18px;
    // line-height: 25.09px;
  }
  .graph-container {
    width: 100%;
    height: 100%;
    background-color: red;
  }
  .area-chart-container {
    // width:100%;
    height: 300px;
    // background-color: red;
    border-right: 1px solid $table-border;
  }
  .right-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // background-color: red;
    padding-inline-start: 25px;
    .filter-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      // background-color: red;
      justify-content: flex-start;
      p {
        font-family: 'unilever-shilling-medium';
        font-size: 14px;
      }
    }
    .list-container {
      margin-block-start: 20px;
      // background-color: red;
    }
    p {
      font-family: 'unilever-shilling-medium';
      color: $grey-shade-1;
      font-size: 16px;
      font-weight: 600;
    }
    li {
      color: $grey-shade-1;
      font-size: 14px;
    }

    .data-providers-heading {
      margin-bottom: 10px;
      opacity: 0.8;
    }
  }
  .recharts-cartesian-axis {
    .recharts-cartesian-axis-ticks {
      .recharts-cartesian-axis-tick {
        .recharts-cartesian-axis-tick-value {
          font-size: 0.7rem;
        }
        .recharts-cartesian-axis-tick-line {
          display: none;
        }
      }
    }
  }
  .recharts-yAxis {
    .recharts-label,
    .recharts-text {
      font-size: 0.75rem;
      font-weight: bold;
    }
  }
  .recharts-xAxis {
    .recharts-label,
    .recharts-text {
      font-size: 0.85rem;
    }
  }
  .recharts-surface {
    min-height: 24rem;
  }
  .data-providers {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 0;
    .data-provider {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      &-dot {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
      }
      &-name {
        list-style: none;
        font-size: 0.9rem;
        color: $tab-font-grey;
        letter-spacing: 0.1px;
      }
      .dot-0 {
        background: $confidence-medium;
      }
      .dot-1 {
        background: $data-provider-2;
      }
      .dot-2 {
        background: $data-provider-3;
      }
    }
  }
}
