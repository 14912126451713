@import "src/styles/main";
.search-input-field{
    position: relative;
    width:100%;
    .search-input-field-text{
        padding-left: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px;
        font-family: "unilever-shilling";
        font-size:12px;
        font-weight: 400;
        line-height: 20px;
        height: 44px !important;
    }
    .card-template-header-left-icon{
        position: absolute;
        width: 15px;
        height: 15px;
        left: 15px;
        top: 16px;
        // z-index: 5;
    }
    img {
        width:20px;
        height: 20px;
    }
}