@import "src/styles/main";

.container {
    width: 100%;
    height: 44px;
    background-color: $table-header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px 15px 15px
}

.item-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 17px 40px 15px 0px;

    h1 {
        line-height: 12px;
        font-size: 12px;
        font-weight: 600;
    }

    p {
        line-height: 18px;
        font-size: 12px;
        font-weight: 400;
        margin-top: 9px;
        margin-left: 5px;
    }
}

.item {
    display: flex;

    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 8px;
}

.warning {
    color: rgba(255, 33, 33, 1);
}

.success {
    color: $success-tag
}