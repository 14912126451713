@import "src/styles/main";

.nav-font-buttons {
    width:"100%";
    &.btn-styles {
        background: $unilever-white;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $unilever-white;
        border-radius: 0px;
        width:36px;
        height:36.99px;
        color: $main-text-black;
        font-weight: 400;
        font-size: 16px;
        padding:10px 16px;
        font-family: 'unilever-shilling', sans-serif;
        &.btn-active {
            border:1px solid $unilever-blue;
            color:rgba(31, 54, 199, 1);
            background-color: rgba(235, 237, 250, 1);
        }
    }    
}

.action-button {
    &.btn-styles {
        background: $unilever-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $unilever-blue;
        padding:14px 24px;
        width:104px;
        height:48px;
        border-radius: 2px;
        font-size:12px;
        font-family: 'unilever-shilling';
        color: $unilever-white;
    }
    &:hover {
        background-color: $unilever-hover-blue;
    }
    &:disabled {
        background-color: $unilever-disabled-grey;
        color:$unilever-white;
    }
  
}


.btn-container {
    // height: 48px;
    display: flex;

    justify-content: center;
    align-items: center;
    padding:14px 24px 14px 24px;
    border:0px;
    border-radius:0px;
    font-size: 14px;
    line-height: 20px;
    
    &.disabled {
        background-color: $unilever-disabled-grey;
        color:$unilever-white;
    }
    &.primary {
        background-color: $primary-color;
        color:$unilever-white;
        
    }
    &.secondary {
        background-color: transparent;
        color:$unilever-blue;
        border:1px solid $unilever-blue;
        &.pressed {
            border:2px solid $primary-color;
            background-color: $table-header;
        }
    }
    &.status {
        max-height:35px;
        font-size:12px;
        padding-inline:10px;

        background-color: transparent;
        font-family: 'unilever-shilling';
        border:2px solid $unilever-disabled-grey;
        color:$table-body-font-color;
        &.pressed {
            border:2px solid $primary-color;
            background-color: $table-header;
            color:$primary-color;
            font-weight: 600;
        }
        
        
    }
    
    
}

