@import "src/styles/main";

.home-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  justify-content: center;

  // background-color: red;
  .top-row-container {
    display: flex;
    // padding: 0px;
    padding-inline-start: 12px;
    // margin: 0px;
    // background-color: red;
    justify-content: space-between;
  }

  .home-page-title {
    // background-color: red;
    padding: 0px;
    margin: 0px;

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.2px;
      color: #000000;
    }

    p {
      font-family: 'unilever-shilling';
      margin-block-start: 10px;
      margin-block-end: 30px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 30px;
      letter-spacing: 0.2px;
      color: $grey-shade-1;
    }
  }

  .filters-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 0px;

    // padding-inline:0px;
    // padding-inline-end: 0px;
    // justify-content: flex-end;
    // gap:27px;
    // background-color: green;
    .filter-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-inline-end: 12px;
      gap: 12px;
      // background-color: red;
      justify-content: flex-end;

      p {
        font-family: 'unilever-shilling-medium';
      }
      &:last-child{
        padding-right: 0;
      }
    }

    .filter-text {
      padding-right: 3px;
      font-size: 12px;
    }
  }

  .pie-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 0px;
    margin: 0px;
    flex-wrap: nowrap;
    // background-color: green;
  }

  .line-chart-row {
    display: flex;
    align-items: stretch;
    width: 100%;
    padding: 0px;
    margin: 0px;
    // background-color: red;
  }

  .area-chart-row {
    width: 100%;
    padding: 0px;
    margin: 0px;
    // background-color: red;

  }

}