@import "src/styles/variables";

.period-mapped-details {

  .table-container {

    // Table header frist row
    thead tr:nth-child(1) {

      th:nth-child(4),
      th:nth-child(5),
      th:nth-child(7),
      th:nth-child(8) {
        background-color: $table-header-data-cell-color;
      }

      th:nth-child(2),
      th:nth-child(3) {
        border-left: 1px solid $table-border;
      }

      th:nth-child(5) {
        border-left: 2px solid $table-border;
      }
    }

    // Table header 2nd row
    thead tr:nth-child(2) {

      th {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
        color: $main-text-black;
      }

      th:nth-child(2),
      th:nth-child(3),
      th:nth-child(4),
      th:nth-child(5),
      th:nth-child(6),
      th:nth-child(7),
      th:nth-child(8),
      th:nth-child(9),
      th:nth-child(10),
      th:nth-child(11),
      th:nth-child(12) {
        border-left: 1px solid $table-border;
      }

    }

    // Table body
    tbody tr td:nth-child(7),
    tbody tr td:nth-child(8),
    tbody tr td:nth-child(9),
    tbody tr td:nth-child(10),
    tbody tr td:nth-child(11)
     {
      border-left: 1px solid $table-border;
    }

    tbody tr td:nth-child(13) {
      border-left: none;
    }

    tbody tr td:nth-child(12) {
      border-right: none;
    }

    .cell-padding {
      padding: 10px 16px;
    }
  }

}