@import 'src/styles/main';
.line-chart-card {
  // width:890px;
  padding-block-start: 24px;
  padding-block-end: 33px;
  padding-inline-start: 22px;
  padding-inline-end: 22px;
  // margin-block-start:31px;
  // margin-inline:0px;
  box-shadow: -10px 10px 20px 0px rgba(30, 30, 30, 0.05);
  background-color: $unilever-white;
  // background-color: green;
  .filter-container {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    // justify-content: space-around;
    p {
      font-family: 'unilever-shilling-medium';
    }
  }

  .card-title-container {
    // background-color: red;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    // margin-block-start:24px;
    margin-block-end: 15px;

    .card-title-container-header {
      font-weight: 600;
      font-family: 'unilever-shilling';
      color: $main-text-black;
      opacity: 0.7;
    }
  }
  .card-dropdown-container {
    // background-color: green;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-block-end: 31px;
    h2 {
      font-family: 'unilever-shilling-medium';
      color: $primary-color;
      font-size: 12px;
      margin: 0 0 0.25rem 0;
    }
    .download-container {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      // background-color: red;
      padding-inline-end: 38px;
      gap: 5px;

      .download-container-text {
        font-weight: 600;
      }
    }
  }
  h3 {
    font-family: 'unilever-shilling-medium';
    font-size: 18px;
    // line-height: 25.09px;
  }
  .recharts-cartesian-axis {
    .recharts-cartesian-axis-ticks {
      .recharts-cartesian-axis-tick {
        .recharts-cartesian-axis-tick-value {
          font-size: 0.7rem;
        }
        .recharts-cartesian-axis-tick-line {
          display: none;
        }
      }
    }
  }
  .recharts-yAxis {
    .recharts-label,
    .recharts-text {
      font-size: 0.75rem;
      font-weight: bold;
    }
  }
  .recharts-xAxis {
    .recharts-label,
    .recharts-text {
      font-size: 0.85rem;
    }
  }
  .data-providers {
    display: flex;
    gap: 1.5rem;
    padding-left: 0;
    .data-provider {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      &-dot {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
      }
      &-name {
        list-style: none;
        font-size: 0.9rem;
        color: $tab-font-grey;
        letter-spacing: 0.1px;
      }
      .dot-0 {
        background: $data-provider-1;
      }
      .dot-1 {
        background: $data-provider-2;
      }
      .dot-2 {
        background: $data-provider-3;
      }
    }
  }
  .data-provider-title {
    font-size: 0.8rem;
    color: $tab-font-grey;
    font-weight: bold;
    margin: 0;
    height: fit-content;
  }
  .data-provider-total-count {
    font-size: 1.25rem;
    color: $table-body-font-color;
    margin: 0;
    font-family: 'unilever-shilling-bold';
  }
  .data-provider-total-req {
    font-size: 0.85rem;
    color: $tab-font-grey;
    font-weight: 100;
  }
  .recharts-surface {
    height: 24rem;
  }
}
